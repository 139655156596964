
.video {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100vh;
    background: url('../../../public/images/video/bg.png')  5% 0 / auto 100% no-repeat,
    url('../../../public/images/video/bg.png')  95% 0 / auto  100% no-repeat;

    @media screen and (max-width: 1024px){
        height: 55vh;
    }

    &__content {
        width: 1615px;
        height: auto;
        position: relative;
        margin: 0;

        @media screen and (max-width: 1899px) {
            margin: 0 150px;
        }

        @media screen and (max-width: 1024px) {
            width: 85%;
            margin: 0 20px;
        }


        .video-layer {
            width: 100%;
            height: 100%;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__play-button{
        width: 112px;
        height: 116px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;

        @media screen and (max-width: 1799px){
            width: 84px;
            height: 88px;
        }

        @media screen and (max-width: 1799px){
            width: 64px;
            height: 57px;
        }
    }



   
    
}

@media (max-width: 750px) {
    .video {
        background: url('../../../public/images/video/bg.png')  0 0 / auto 100% no-repeat,
    url('../../../public/images/video/bg.png') 100% 0 / auto  100% no-repeat;;
        &__content {
            width: 90%;
        }
    }
}
   



