@import './variables';



.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 105px 0 8px;
    background: rgba(39, 36, 47, 0.75);
    z-index: 4;


    .header__menu ul {
        display: flex;
        gap: 40px;
        margin-right: 200px;
    }

    .header__menu ul li{
        list-style: none;
        font-family: 'KoHo', sans-serif;
        cursor: pointer;
    }

    .header__menu a {
        font-size: 20px;
        color: #ffffff;
    }
    
    &__info {
        display: flex;
        align-items: center;
    }

    &__socials {
        display:flex;
        align-items: flex-start;
        margin-right: 47px;

        .twitter {
            margin-right: 26px;
        }
    }

    &__logo {
        margin-right: 10px;
    }
    
    &__list-btn {
        width: 204px;
        height: 48px;
        font-family: 'Industry', sans-serif;
        font-weight: 600;
        font-size: 20px;
        text-align: center;
        color: white;
        background: none;
        border: 3px solid $purple;
        white-space: nowrap;

        &:hover {
            background-color: #7D67A8;
        }
    }
    
}

@media  (max-width: 1250px) {
    .header {
        padding: 0px 30px 0px 8px;
         
    }

   
}

@media  (max-width: 750px) {
    .header {
        padding: 0px 22px 0px 8px;

        &__logo {
            img {
                width: 220px;
            }
        }

        &__list-btn {
        font-size: 25px;
        padding: 8px 3px;
    }
        
    
    &__socials {
        margin-right: 26px;

        .twitter {
            margin-right: 15px;
        }
    }

  
    }
}

@media  (max-width: 550px) {
    .header {
        height: 60px;

        &__logo  {
            img {
                width: 90px;
                height: auto;
            }
        }
        
        .twitter {
            margin-right: 13px;
        }

        &__socials {
            img {
                width: 21px;
            }
            margin-right: 13px;
        }

        
        &__list-btn {
            padding: 8px 20px;
            font-size: 14px;
            line-height: 17px;
        }
    
         
    }
}
