

.battlemech {
    width: 100%;
    background-image: url("../../../public/images/battlemech/bg_ab_1.png");
  background-size: cover;
  background-repeat: no-repeat;
    height: 100vh;
    padding: 0 94px;
  overflow: hidden;

  @media screen and (max-width: 1499px){
    padding: 0 35px;
  }

    //background: rgba(31, 28, 37, 0.8);
    &__content {
        display: flex;
        height: 100%;
        align-items: center;
        position: relative;

    }
    &__img {
      max-width: 1028px;
      height: auto;
      z-index: 2;
        img{
            width: 100%;
            height: 100%;
        }
   }
   &__text {
        width: 50%;
        margin-bottom: 110px;
     position: relative;


     .line-horizontal, .line-vertical{
       position: absolute;
     }

     .line-horizontal{
       top: -300px;
       left: -80px;

       @media screen and (max-width: 1699px){
         top: -245px;
       }

     }

     .line-vertical{
       top: 40px;
       left: -400px;
     }

     h2{
       color: #FFF;
       font-family: Industry-Demi, sans-serif;
       font-size: 32px;
       font-style: normal;
       font-weight: 400;
       line-height: normal;
       text-transform: uppercase;
       margin-bottom: 90px;

       @media screen and (max-width: 1699px){
         font-size: 28px;
       }

       @media screen and (max-width: 1599px){
         font-size: 24px;
       }
     }

     @media screen and (max-width: 1024px){
       position: unset;
       width: 100%;
     }

     @media screen and (max-width: 768px){
       position: relative;
       width: 100%;
       padding: 20px;
     }
   }
        p {
            //display: flex;
            margin: 0 0 30px 0;
            max-width: 582px;
            font-family: 'KoHo', sans-serif;
            font-weight: 500;
            font-size: 24px;
            line-height: 34px;
            color: #FFFFFF;
            position: relative;

              @media screen and (max-width: 1699px){
                font-size: 18px;
              }

              @media screen and (max-width: 1024px){
               margin: 3% 5% 0 5%;
                padding: 0 0 50px 0;
                max-width: 100%;
              }

              @media screen and (max-width: 768px){
                margin: 0 0 0 5%;
              }


          span{
            margin-left: 20px;
          }

                &::before{
                  position: absolute;
                  top: 0;
                  left: -55px;
                  content: '';
                  background-image: url("../../images/battlemesh/point.png");
                  background-repeat: no-repeat;
                  background-size: cover;
                  width: 30px;
                  height: 32px;
                  z-index: 3;
                }
            }
  
    &__title {
        margin: 74px 0px 50px 0px;
      padding: 40px 0 0 0;
    }

   
    
}

@media (max-width: 550px) {
        .battlemech {
            &__content {
                display: flex;
                flex-direction: column;
            }
            &__text {
                width: 100%;
                position: static;
                margin: 0 auto;
                background: none;
                p {
                margin: 13px 27px;
                font-size: 14px;
                line-height: 18px;
                }
            }
        }
}
