.weapons {
    margin-bottom: 26px;

    &__slider {
        margin-bottom: 28px;
    }

    &__item {
        img {
            width: 100%;
            height: 100%;

        }
    }

    &__title {
        padding: 26px 0 19px;
    }

    .swiper-pagination {
        position: absolute;
        right: 0;
        top: 90%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;


        &-bullet {

            width: 100%;
            max-width: 200px;
            height: 7px;
            background: rgba(255, 255, 255, 0.55);
            border-radius: 0;

            &-active {
                background: #AEA0FF;
            }
        }

    }

    &__text {
        margin-top: -45px;
        background: url('../../../public/images/weapons/textBg.png') center / cover no-repeat;
        padding: 28px 15px 120px 61px;

        p {
            margin: 0 auto;
            max-width: 1309px;
            font-family: 'KoHo',sans-serif;
            font-weight: 500;
            font-size: 24px;
            line-height: 34px;
            color: #FFFFFF;
            opacity: 0.8;
        }
    }
}


@media (max-width: 1250px) {
    .weapons {
        .swiper-pagination {

            &-bullet {
                max-width: 100px;
                height: 5px;

            }

        }

        &__text {
            p {
                font-size: 20px;
            }
        }

    }
}




@media (max-width: 550px) {
    .weapons {
        .swiper-pagination {
            &-bullet {
                max-width: 60px;
                height: 2px;

            }

        }


        &__text {
            padding: 28.82px 36px  40px 61px;
            p {
                font-size: 14px;
                line-height: 18px;
            }
        }

    }

}
