.how-earn {
    background: url('../../../public/images/earn/earnBg.png') top center / 100% 100% no-repeat;
    padding: 0 15px;

    @media screen and (max-width: 1024px){
        background-size: cover;
        background-position: unset;
    }

    &__title {
    padding-top: 20px;

        @media screen and (max-width: 1024px){
            font-size: 40px;
        }
    }

    &__content {
        background-image: url('../../../public/images/earn/earnBg2.png');
        margin: 0 auto;
        display: flex;
        justify-content: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: right;

        @media screen and (max-width: 1024px){
            background-size: cover;
            background-position: unset;
            flex-direction: column;
            align-items: center;
        }
    }

    &__gallery {
        max-width: 763px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 66px;
        flex: 0 0 52.5%;

    &-row {
        display: flex;
        .hero {
            img {
                width: 100%;
                height: auto;
            }
        }
      }
  
    }

    &__info {
        flex: 0 0 47.5%;
        margin-top: 133px;
        max-width: 590px;
        margin-left: 86px;
        font-family: 'KoHo', serif;
        font-weight: 500;
        font-size: 22px;
        line-height: 34px;

        .cursive{
            color: #AEA0FF;
            margin-bottom: 30px;
            font-style: italic;

            span{
                margin-left: 20px;
            }
        }

        .text, .text-2{
            margin-bottom: 30px;

            span{
                margin-left: 20px;
            }
        }

        .text-2{
            color: #AEA0FF;
        }

        @media screen and (max-width: 1024px){
            max-width: 80%;
            margin: 20px 0 90px 0;
        }
    }

    .methods-list {
      padding-left: 40px;
      margin-right: 40px;
    }


    .hero {
        margin: 0 19.56px 19.56px 0;
    }
}
