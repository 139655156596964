.why {
    background: url('../../../public/images/why/bg.png') center/cover no-repeat;
    height: 100vh;
    padding: 0 15px;

    &__container {
        max-width: 1520px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &__title {
        margin: 60px 0px 63px 0px;
        font-size: 40px !important;

        @media screen and (max-width: 1024px) {
            margin: 60px 0px 63px 0px;
        }
    }

    &__content {
        width: 1440px;
        margin-bottom: 104px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        @media screen and (max-width: 1599px){
            width: 1235px;
        }

        @media screen and (max-width: 1299px){
            width: 1035px;
        }

        &-item,
        &-item-second,
        &-item-third {
            width: 720px;
            height: 267px;
            display: flex;
            justify-content: space-between;
            position: relative;
            margin-bottom: 26px;
            background-size: cover;

            &:nth-child(even) .layaut:hover {
                transform: translate(0, 0);
            }

            &:nth-child(odd) .layaut:hover {
                transform: translate(-20%, 0);
            }

            .img {
                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .layaut {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-15%, 0);
                display: flex;
                justify-content: center;
                align-items: center;
                background-image: url('../../../public/images/why/image1_layer.png');
                background-repeat: no-repeat;
                background-size: contain;
                width: 420px;
                height: 100%;
                transition: .2s ease-in-out;

                @media screen and (max-width: 1599px){
                    width: 400px;
                }

                @media screen and (max-width: 1299px){
                    width: 330px;
                }

                .btc-icon {
                    position: absolute;
                    top: 50%;
                    left: 2%;
                    transform: translate(-50%, -50%);

                    @media screen and (max-width: 1599px){
                        left: 1%;
                        width: 100px;
                    }

                    @media screen and (max-width: 1299px){
                        left: 0;
                        width: 75px;
                    }

                    img{
                        width: 100%;
                        height: 100%;
                    }
                }

                &_text {
                    width: 296px;
                    color: #fff;
                    font-family: KoHo, sans-serif;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    margin-left: 30px;

                    @media screen and (max-width: 1599px){
                        width: 280px;
                        font-size: 18px;
                        margin-left: 0;
                    }

                    @media screen and (max-width: 1299px){
                        width: 220px;
                        font-size: 15px;
                        margin-left: 0;
                    }
                }
            }

            @media screen and (max-width: 1599px){
                width: 615px;
                height: 220px;
            }

            @media screen and (max-width: 1299px){
                width: 515px;
                height: 190px;
            }
        }

        &-item {
            background-image: url('../../../public/images/why/image1_bg.png');
        }

        &-item-second {
            background-image: url('../../../public/images/why/image2_bg.png');

            .layaut{
                background-image: url('../../../public/images/why/image2_layer.png');
            }
        }

        &-item-third {
            background-image: url('../../../public/images/why/image3_bg.png');

            .layaut{
                transform: translate(-27%, 0);

                &:hover{
                    transform: translate(0%, 0);
                }
            }

            &:last-child{
                background-image: url('../../../public/images/why/image4_bg.png');
                .layaut{
                    background-image: url('../../../public/images/why/image2_layer.png');

                    &_text{
                        p{
                            width: 377px;

                            @media screen and (max-width: 1599px){
                                width: 315px;
                            }

                            @media screen and (max-width: 1299px){
                                width: 275px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1250px) {
    .why {
        .lable {
            min-width: 200px;
        }
    }
}

@media (max-width: 550px) {
    .why {
        &__title {
            margin: 55px 0 16px 0;
        }
    }
}
