@import './variables';



.preview {
  display: flex;
  height: calc(100vh);
  align-items: flex-end;
  justify-content: center;
  position: relative;
}

.preview__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
}

.preview video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.preview__content {
  position: absolute;
  top: 35%;
  //left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1031px;
  margin-bottom: 50px;
  z-index: 3;
}

.preview__img img {
  width: 100%;
  height: auto;
}

.preview__title {
  width: 546px;
  margin: -75px auto;

  font-family: 'Industry', sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #FFFFFF;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.65);
  text-transform: uppercase;
}




@media (max-width: 1250px) {

    .preview  {
        &__title {
            font-size: 40px;
           
        }
    }
    
}


@media (max-width: 750px) {
   
    
}



@media (max-width: 550px) {
    .preview {
        height: calc(100vh - 60px);
        &__title {
            margin: 0px;
            font-size: 20px;
            line-height: 30px;
           
        }

        &__content {
            max-width: 300px;
            margin-bottom: 5px;
        }
    }
    
}
