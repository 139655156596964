.discord {
    font-family: 'Industry', sans-serif;
    position: relative;
    z-index: 4;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 132px;
        margin-right: 130px;
     
    }

    &__list {   
        margin-right: 81px;
        transition: all 0.3s ease;

        &:hover {
            background-color: #7D67A8;
        }

        &-btn {
            border: 3px solid #875ACE;
            padding: 23px 83px;
            background: none;

            font-weight: 600;
            font-size: 36px;
            line-height: 48px;
            text-align: center;
            color: #FFFFFF;
            text-shadow: 0 0 15px rgba(255, 255, 255, 0.35);
        }
    }

    &__join {
        font-weight: 700;
        font-size: 60px;
        line-height: 72px;
        text-transform: uppercase;
        text-shadow: 0 0 15px rgba(255, 255, 255, 0.35);
        transition: all 0.3s ease;
        &:hover {
            transform: scale(1.1);
        }
        
        img {
            margin-right: 15px;
        }

        &-bottom {
            display: flex;
            align-items: flex-start;
        }

        span  {
            font-size: 50px;
            line-height: 35px;
        
        }
    }
}
