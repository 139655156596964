.pilots {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    position: relative;

    &-title{
        background: url('../../../public/images/pilots/titleBg.png') center top / 100% auto no-repeat;
        width: 100%;
        height: 130px;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;


        h1{
            font-size: 48px;
        }
    }

    &__content {
        background: url('../../../public/images/pilots/pilotsBg.png') center top / 100% auto no-repeat;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 5px 44px 149px;
        height: 100%;

        @media screen and (max-width: 1024px){
            padding: 0 79px 25px 149px;
            flex-direction: column;
        }

        @media screen and (max-width: 768px){
            padding: 30px 35px 25px 80px;
        }

        &-text{
            margin-top: 80px;

            p{
                width: 610px;
                color: #FFF;
                font-family: KoHo, sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                margin-bottom: 40px;
                position: relative;

                @media screen and (max-width: 1699px){
                    font-size: 22px;
                }

                span{
                    margin-left: 20px;
                }

                &::before{
                    position: absolute;
                    top: 0;
                    left: -55px;
                    content: '';
                    background-image: url("../../images/battlemesh/point.png");
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 30px;
                    height: 32px;
                    z-index: 3;
                }
            }
        }

        &-img{
            display: flex;
            justify-content: flex-end;
            position: relative;
            width: 52%;



            .first{
                width: 485px;

                @media screen and (max-width: 1799px){
                    width: 430px;
                }

                @media screen and (max-width: 1699px){
                    top: 50px;
                    left: 120px;
                }

                position: absolute;
                top: 145px;
                left: 130px;

                img{
                    width: 100%;
                    height: 100%;
                }
            }

            .second{
                width: 485px;


                @media screen and (max-width: 1799px){

                }

                @media screen and (max-width: 1699px){
                    width: 420px;
                }

                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }

    }

}
