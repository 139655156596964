
.factions {
    margin-bottom: 86px;
    &__content  {
        margin: 0 89px 0 97px;
    }
    &__title  {
       width: 100%;
        height: 188px;
        background-image: url('../../../public/images/factions/faction-right.png');
        background-position: right;
        background-repeat: no-repeat;
        background-size: contain;
       margin-bottom: 44px;
        display: flex;
        justify-content: center;
        align-items: center;
       //box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
       //border-radius: 90px;

        .factions__btn{
            background: none ;
            align-self: center;
            background: transparent;

            img{
                margin-left: 35px;
            }
        }
    }

  
    .more {
        background: none;
            img {
            &:hover {
              
                animation: move 0.5s infinite 0.1s;
            }
        }

            @keyframes move {
                0% {
                    transform: translateY(10px);
                } 50% {
                    transform: translateY(0px);
                }
                100% {
                    transform: translateY(10px);
                } 
                }
    }

    .faction {
        display: flex;
        margin-bottom: 101px;
        text-align: left;

        @media screen and (max-width: 1024px){
            flex-direction: column;
        }

        &-right {
            flex-direction: row-reverse;
            text-align: right;

            @media screen and (max-width: 1024px){
                flex-direction: column;
            }
        }

        &__img {
            width: 50%;

            @media screen and (max-width: 1024px){
                width: 100%;
            }
            img {
                width: 100%;
                height:auto;
            }
        }
    

    

        &__info {
            margin: 90px 33px 56.33px 63px;
            display: flex;
            flex-direction: column;

        }
        
        &__title {          
            font-family: 'KoHo', sans-serif;
            font-weight: 700;
            font-size: 30px;
            line-height: 39px;
            text-transform: uppercase;
            color: #FFFFFF;
            margin-bottom: 55px;
            text-align: left;

            @media screen and (max-width: 1024px){
                text-align: start;
            }
        }

        &__desc {
            max-width: 742px;
            font-family: 'KoHo', sans-serif;
            font-weight: 500;
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 22px;
            text-align: left;

            @media screen and (max-width: 1024px){
                text-align: start;
            }

        }

        &__btn {
            background: none ;
            align-self: center;
        }
    }

    
}
