
.planets {
    background: url('../../../public/images/planets/bg-left.png')  -20% top    / auto no-repeat;
    padding-bottom: 119px;

    &__title {
   
    min-height: 79.54px;
    padding: 17px 0;
    margin-bottom: 76px;
    }

    &__body {
        background: url('../../../public/images/planets/space.png') center /  cover no-repeat;
        display: flex;
        min-height:  747px;
        width: 100%;

        @media screen and (max-width: 1024px){
            flex-direction: column-reverse;
            min-height:  600px;
        }
    }
    &__img {
        position: relative;
        width: 50%;

        @media screen and (max-width: 1024px){
            width: 70%;
        }
        img {
            transition: all .3s linear;
        }
     

        &-red {
            position: absolute;
            top: 41px;
            right: 147px;
            z-index: 10;
            width: 570px;

            @media screen and (max-width: 1024px){
                top: -573px;
                width: 350px;
            }

            @media screen and (max-width: 768px){
                width: 250px;
            }

            

        
            &.active {
                width: 400px;
                cursor: pointer;
                z-index: 0;

                @media screen and (max-width: 768px){
                    width: 300px;
                }
               
            }
        }

        &-green {
            position: absolute;
            bottom: 59px;
            right: 0;
            z-index: 0;          
            width: 450px;

            @media screen and (max-width: 1024px){
                bottom: 59px;
                width: 300px;
                top: -515px;
            }

            @media screen and (max-width: 768px){
                width: 250px;
            }

            &.active {
                width:  382px;
                cursor: pointer;
                z-index: 0;

                @media screen and (max-width: 1024px){
                    bottom: 59px;
                    width: 300px;
                }

                @media screen and (max-width: 768px){
                    width: 250px;
                }
               
            }
        }
    }
    &__text {
        width: 50%;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1024px){
            width: 100%;
        }
        p {
            margin-left: 70px;
            font-family: 'KoHo', sans-serif;
            max-width: 603px;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 34px;
            color: #FFFFFF;

            @media screen and (max-width: 1024px){
                max-width: 100%;
                margin: 20px 50px;
            }
        }
    }
}


@keyframes red {
    50% {
        transform: scale(0.5);
    }

    100% {

    }

}
