
.origin {
    width: 100%;
    margin-bottom: 86px;
    margin-top: 125px;
    &__content {
        background: url('../../../public/images/origin/borderLeft.png') 63px 0px /  auto   no-repeat;
        padding: 0 89px 0 97px;
        .itemOne {
            background: url('../../../public/images/origin/itemBgBig.png') 0 0  / cover no-repeat;
            display: flex;
            justify-content: space-between;
            margin-bottom: 65px;
            margin-left: 62px;

            @media screen and (max-width: 1024px){
                flex-direction: column;
                margin-left: 0;
                margin-bottom: 0;
                padding-bottom: 110px;
            }

            &__img {
                width: 50%;
                margin-top: 52px;
                @media screen and (max-width: 1024px){
                    order: 1;
                    margin: 0;
                    width: 100%;
                }
              img {
                width: 100%;
                height: auto;
                object-fit: cover;
              } 
            }
            &__text {
                width: 50%;
                margin: 0 44px;
                max-width: 681px;;
                display: flex;
                align-items: center;
                justify-content: center;

                @media screen and (max-width: 1024px){
                    order: 2;
                    width: 100%;
                    margin: 10px 0 0 0;
                    padding: 0 40px;
                }

                @media screen and (max-width: 768px){
                    margin: 10px 0 0 0;
                }
                p {
            font-family: 'KoHo', sans-serif;
            font-weight: 500;
            font-size: 24px;
            line-height: 34px;
            text-align: left;
                }
            }
        }

        .itemTwo {
            background: url('../../../public/images/origin/itemTwoImg.png') 0 0 / auto no-repeat;
            min-height: 630px;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 65px;

            @media screen and (max-width: 1024px){
                flex-direction: column;
                background-size: contain;
                min-height: 630px;
            }
            &__lable {
                padding: 72px 19px 18px 132px;
                background: url('../../../public/images/origin/itemTwoBg.png') 0 0 / auto no-repeat;

                @media screen and (max-width: 768px){
                    padding: 50px 15px 38px 165px;
                }
                &-text {
                    max-width:  681px;
                    min-height: 214px;
                    p {
                    font-family: 'KoHo', sans-serif;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 34px;
                    text-align: left;
                    }
                }
            }
            
        }
        .itemThree {
            background: url('../../../public/images/origin/itemBgFour.png') 0 center / auto no-repeat;
            display: flex;
            justify-content: space-between;
            margin-left:63px;
            margin-bottom: 65px;

            @media screen and (max-width: 1024px){
                flex-direction: column-reverse;
                margin-left: 0;
                padding-bottom: 30px;
                background-size: cover;
            }
            &__img {
                width: 42.7%;

                @media screen and (max-width: 1024px){
                    width: 100%;
                    text-align: center;
                }
                img {                
                    max-width: 100%;
                    height: auto;
                }
            }
            &__text {
                width: 57.3%;
                display: flex;
                justify-content: center;
                align-items: center;

                @media screen and (max-width: 1024px){
                    width: 100%;
                    padding: 0 40px;
                }
                p {
                max-width: 681px;
                font-family: 'KoHo', sans-serif;
                font-weight: 500;
                font-size: 24px;
                line-height: 34px;
                text-align: center;
                }
            }
        }

        .itemFour {
            background: url('../../../public/images/origin/itemFourImg.png') 0 0 / auto no-repeat;
            min-height: 630px;
            display: flex;
            justify-content: end;
            align-items: end;
            margin-bottom: 96px;

            @media screen and (max-width: 1024px){
                background-size: contain;
                min-height: 580px;
            }

            &__lable {
                background: url('../../../public/images/origin/itemBgSmall.png') 0 0 / auto no-repeat;
                padding: 86px 76px 38px 165px;

                @media screen and (max-width: 1024px){
                    padding: 20px 76px 121px 165px;
                }

                @media screen and (max-width: 768px){
                    padding: 20px 76px 38px 165px;
                    background-size: contain;
                    min-height: 550px;
                }
                &-text {
                    max-width:  681px;
                    p {
                    font-family: 'KoHo', sans-serif;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 34px;
                    text-align: center;
                    }
                }
            }
            
        }
    }

    &__title  {
        width: 100%;
        //padding: 53px 0;
        background-image: url('../../../public/images/factions/faction-left.png');
        background-repeat: no-repeat;
        background-size: contain;
        //margin-bottom: 33.27px;
        height: 188px;
        margin-bottom: 50px;
        display: flex;
        align-items: center;
        justify-content: center;


        img{
            margin-left: 35px;
        }

        .origin__btn{
            background: none ;
            align-self: center;
            background: transparent;
        }


        .more {
            background: none;
            img {
                &:hover {

                    animation: move 0.5s infinite 0.1s;
                }
            }

            @keyframes move {
                0% {
                    transform: translateY(10px);
                } 50% {
                      transform: translateY(0px);
                  }
                100% {
                    transform: translateY(10px);
                }
            }
        }

    }
}
