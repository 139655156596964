.footer {
    border-top: 5px solid rgba(188, 146, 64, 0.40);
    background: rgba(31, 28, 37, 0.70);
    box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 4;

    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #3C3C3C;


        &-socials{
            display: flex;
            align-items: center;
            gap: 26px;
            margin-right: 144px;
            img{
                padding: 0!important;
            }
        }

        &-link{
            ul{
                display: flex;
                gap: 40px;
                li{
                    color: #FFF;
                    font-family: KoHo, sans-serif;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-transform: capitalize;
                    list-style: none;
                    cursor: pointer;
                    transition: .1s ease-in;

                    &:hover{
                        color: #f8f8f8;
                    }
                }
            }
        }

        img {
            padding: 60px 0 94px 24px;
            max-width: 100%;
            height: auto;
        }
    }

    &__bottom {
        padding: 32px 47px 32px 69px;
        display: flex;
        justify-content: center;
        align-items: center;


        &-socials {
            display: flex;
            align-items: flex-start ;
            gap: 24px;
        }

        p {
        font-family: 'KoHo', sans-serif;
        font-weight: 600;
        font-size: 26px;
        line-height: 34px;
        text-align: center;
        color: #818181;
        }
    }
}
